.pre_recording {
    white-space: pre-line;
    overflow: scroll;
    opacity: 1;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(4px);
    transition: opacity 0.5s ease;
}

.pre_recording > .close {
    margin-top: 50px;
    margin-bottom: 100px;
    font-weight: 300;
    text-decoration: underline;
    font-size: 23px;
    cursor: pointer;
}

.pre_title {
    margin-top: 50px;
    font-weight: 400;
    font-size: 25px;
}

.pre_module {
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
    transition: transform 0.5s ease, height 0.5s ease, opacity 0.5s ease;
}

.pre_module > .label_title {
    font-weight: 400;
    font-size: 25px;
}

.star_container {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
}

.star_container > .rating_star {
    max-width: 80px;
    stroke: white;
    stroke-width: 2px;
    display: inline-block;
    width: 18%;
    margin: 1%;
}


.star_container > .rating_bubble {
    display: inline-block;
    line-height: 50px;
    width: 50px;
    height: 50px;
    font-size: 20px;
    border-radius: 200px;
    border: 1px solid white;
    margin: 1%;
    cursor: pointer;
}

.star_container > .rating_bubble:hover {
    background-color: white !important;
    color: black;
}

.satisfaction_choice {
    font-size: 24px;
    margin: 10px;
    border-radius: 100px;
    line-height: 40px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid white;
    width: 80%;
    max-width: 500px;
    cursor: pointer;
}

.satisfaction_choice:hover {
    color: black;
    background-color: rgba(255, 255, 255, 0.7);
}