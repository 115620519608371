.brand_page {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    display: block;
    min-height: 100vh;
    width: 100vw;
}

.brand_container {
    min-height: 100vh;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
}

.brand_header{
    padding-top: 12px;
    line-height: 17vh;
    height: 17vh;
    transition: line-height 0.5s ease, height 0.5s ease;
}

.smaller_header{
    line-height: 8vh;
    height: 8vh;
}

.brand_header > img {
    vertical-align: middle;
}

@media (max-width: 850px) {
    .footer_text {
        bottom: 115px !important;
    } 
}

.footer_text {
    margin: 0;
    margin-top: 50px;
    font-size: 14px;
    opacity: 0.7;
}

.loading_text {
    line-height: 90vh;
    font-size: 20px;
    color: rgb(50, 50, 50);
}


.askWhyText {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    transition: opacity 0.2s ease;
    z-index: 10;
    width: min(80%, 500px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 20px;
}

.askWhyText > .title {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 20px;
    font-weight: 400;
}


.askWhyText > .choices > .choice {
    font-weight: 400;
    width: 100%;
    border-bottom:  1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
}

.askWhyText > .choices > .choice:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.askWhyText > .choices > .choice > p {
    margin-left: 20px;
    margin-right: 20px;
    color: rgb(93, 167, 192);
}

.askWhyText > .choices > .choice:first-child {
    width: 100%;
    border-top:  1px solid rgba(255, 255, 255, 0.3);
}

.askWhyText > .choices > .choice:last-child {
    width: 100%;
    border-bottom: none;
}
