@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,400&display=swap');

body {
  background-color: black;
  text-align: center;
  color: white;
  font-family: 'Roboto' sans-serif;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

html, body {
  height: 100%;
}

.App {
  overflow: scroll;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}