@media (max-width: 850px) {
    .post_recording {
        padding-bottom: 250px;
    }
    .post_recording:last-child {
        margin-bottom: 250px;
    }
}

.button_post {
    display: block;
    color: black;
    width: 250px !important;
}

.post_module {
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
    transition: transform 0.5s ease, height 0.5s ease, opacity 0.5s ease;
}

.module_appear {
    transform: scale(0);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.email_input {
    margin-left: auto;
    margin-right: auto;
}

.send_button {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    font-size: 20px;
    height: 24px;
    line-height: 24px;
    width: 100px;
    font-weight: 300;
    text-decoration: none;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}

.email_input > input {
    display: inline-block;
    width: 70%;
}

input:focus, textarea:focus {
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.6);
    outline: 0;
}

input::placeholder, textarea::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

input, textarea {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid white;
    font-family: 'Roboto' sans-serif;
    font-weight: 100;
    color: white;
    line-height: 32px;
    font-size: 19px;
    width: 80%;
    text-align: center;
    border-radius: 10px;
}

.label_title {
    font-weight: 400;
    font-size: 25px;
}

.thank_you > p {
    margin: 20px;
}

.thank_you > img {
    filter: invert(100%) drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.14));
    width: 70px;
}

.form {
    position: fixed;
    top:0;
    background-color: grey;
    height: 100vh;
    width: 100vw;
}

.star_container {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
}

.star_container > .rating_star {
    max-width: 80px;
    stroke: white;
    stroke-width: 2px;
    display: inline-block;
    width: 18%;
    margin: 1%;
}


.star_container > .rating_bubble {
    display: inline-block;
    line-height: 50px;
    width: 50px;
    height: 50px;
    font-size: 20px;
    border-radius: 200px;
    border: 1px solid white;
    margin: 1%;
    cursor: pointer;
}

.star_container > .rating_bubble:hover {
    background-color: white !important;
    color: black;
}

.google_button {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    font-size: 24px;
    height: 35px;
    line-height: 35px;
    width: 200px;
    font-weight: 300;
    text-decoration: none;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}

.post_recording > div:last-of-type {
    padding-bottom: 170px;
}