.main_texts {
    margin-left: 13%;
    margin-right: 13%;
    height: 150px;
    display: block;
    position: relative;
    width: 74%;
}

.main_texts > span{
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.main_texts:first-of-type {
    margin-top: 0;
}

.Micro {
    transition: transform 0.2s ease, opacity 0.2s ease;
    transform: scale(1);
    opacity: 1;
}

.MicroEnd {
    transform: scale(0);
    opacity: 0;
}