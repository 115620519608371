.micro_img {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 130px;
    filter: drop-shadow(3px 1px 3px rgba(0, 0, 0, 0.14));
    transition: opacity 0.5s ease, transform 0.2s ease;
    transform: scale(0);
}

.micro_img.pre_micro {
    animation: 1s ease-in 1s infinite alternate zoom_in_and_out;
}

.micro_img.disappear {
    transform: scale(0);
}

.stop_img {
    padding-top: 4%;
    padding-bottom: 4%;
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 130px;
    display: none;
    filter: drop-shadow(3px 1px 3px rgba(0, 0, 0, 0.14));
    transition: opacity 0.5s ease, transform 0.2s ease;
    transform: scale(0);
}

.stop_img.appear {
    transform: scale(1);
}

.micro_container {
    z-index: 1;
    position: relative;
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.micro_label {
    font-style: italic;
    font-size: 22px;
    height: 44px;
    margin-top: 10px;
    font-weight: 500;
    margin: 0;
}

.loading_micro {
    opacity: 0.5;
    width: 0px;
}

.finished_micro {
    transform: scale(0);
}

@keyframes zoom_in_and_out {
    from { transform: scale(1); }
    to { transform: scale(1.1); }
}

.wave1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    display: block;
    width: 300px;
    height: 300px;
    background: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
    border-radius: 100%;
    transition: transform 0.2s linear;
}

.wave2 {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    width: 200px;
    height: 200px;
    background: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
    border-radius: 100%;
    transition: transform 0.2s linear;
}


.wave_container {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: scale(0) translate(-50%, -50%);
    transform-origin: top left !important;
    transition: transform 0.6s ease;
}

.wave_appear {
    z-index: -1;
    transform: scale(1) translate(-50%, -50%) !important;
}