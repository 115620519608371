.HomeHeader {
    display: block;
    top: 0;
    height: 60px;
    line-height: 60px;
    text-align: center;
    width: 100%;
    background-color: white;
    color: black;
    box-shadow: 0px 2px 3px lightgray;

}

.HomeHeader > p {
    font-weight: 400;
    margin: 0;
    font-size: 25px;
}

.BrandContainers {
    background-color: rgb(240, 240, 240) !important;
    min-height: 100vh;
    padding: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.BrandCard {
    max-width: 250px;
    max-height: 140px;
    height: 28vw;
    line-height: min(28vw, 140px);
    display: block;
    margin: 1%;
    width: 48%;
    border-radius: 8px;
}

.BrandCard > img{
    vertical-align: middle;
    width: 40%;
}