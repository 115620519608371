@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pre_recording {
    white-space: pre-line;
    overflow: scroll;
    opacity: 1;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    transition: opacity 0.5s ease;
}

.pre_recording > .close {
    margin-top: 50px;
    margin-bottom: 100px;
    font-weight: 300;
    text-decoration: underline;
    font-size: 23px;
    cursor: pointer;
}

.pre_title {
    margin-top: 50px;
    font-weight: 400;
    font-size: 25px;
}

.pre_module {
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
    transition: transform 0.5s ease, height 0.5s ease, opacity 0.5s ease;
}

.pre_module > .label_title {
    font-weight: 400;
    font-size: 25px;
}

.star_container {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
}

.star_container > .rating_star {
    max-width: 80px;
    stroke: white;
    stroke-width: 2px;
    display: inline-block;
    width: 18%;
    margin: 1%;
}


.star_container > .rating_bubble {
    display: inline-block;
    line-height: 50px;
    width: 50px;
    height: 50px;
    font-size: 20px;
    border-radius: 200px;
    border: 1px solid white;
    margin: 1%;
    cursor: pointer;
}

.star_container > .rating_bubble:hover {
    background-color: white !important;
    color: black;
}

.satisfaction_choice {
    font-size: 24px;
    margin: 10px;
    border-radius: 100px;
    line-height: 40px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid white;
    width: 80%;
    max-width: 500px;
    cursor: pointer;
}

.satisfaction_choice:hover {
    color: black;
    background-color: rgba(255, 255, 255, 0.7);
}
@media (max-width: 850px) {
    .post_recording {
        padding-bottom: 250px;
    }
    .post_recording:last-child {
        margin-bottom: 250px;
    }
}

.button_post {
    display: block;
    color: black;
    width: 250px !important;
}

.post_module {
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
    transition: transform 0.5s ease, height 0.5s ease, opacity 0.5s ease;
}

.module_appear {
    transform: scale(0);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.email_input {
    margin-left: auto;
    margin-right: auto;
}

.send_button {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    font-size: 20px;
    height: 24px;
    line-height: 24px;
    width: 100px;
    font-weight: 300;
    text-decoration: none;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}

.email_input > input {
    display: inline-block;
    width: 70%;
}

input:focus, textarea:focus {
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.6);
    outline: 0;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
}

input::placeholder, textarea::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

input, textarea {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid white;
    font-family: 'Roboto' sans-serif;
    font-weight: 100;
    color: white;
    line-height: 32px;
    font-size: 19px;
    width: 80%;
    text-align: center;
    border-radius: 10px;
}

.label_title {
    font-weight: 400;
    font-size: 25px;
}

.thank_you > p {
    margin: 20px;
}

.thank_you > img {
    -webkit-filter: invert(100%) drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.14));
            filter: invert(100%) drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.14));
    width: 70px;
}

.form {
    position: fixed;
    top:0;
    background-color: grey;
    height: 100vh;
    width: 100vw;
}

.star_container {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
}

.star_container > .rating_star {
    max-width: 80px;
    stroke: white;
    stroke-width: 2px;
    display: inline-block;
    width: 18%;
    margin: 1%;
}


.star_container > .rating_bubble {
    display: inline-block;
    line-height: 50px;
    width: 50px;
    height: 50px;
    font-size: 20px;
    border-radius: 200px;
    border: 1px solid white;
    margin: 1%;
    cursor: pointer;
}

.star_container > .rating_bubble:hover {
    background-color: white !important;
    color: black;
}

.google_button {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    font-size: 24px;
    height: 35px;
    line-height: 35px;
    width: 200px;
    font-weight: 300;
    text-decoration: none;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}

.post_recording > div:last-of-type {
    padding-bottom: 170px;
}
/**
 * Main wrapper
 */
 .select-search {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    width: 300px;
    position: relative;
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 5;
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 40px 0 16px;
    border: 1px solid white;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
    cursor: auto;
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    background: transparent;
    display: block;
    height: 36px;
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    text-decoration: underline;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    color: #fff;
}

.select-search__option:hover {
    color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: auto;
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
    z-index: 6;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}

.micro_img {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 130px;
    -webkit-filter: drop-shadow(3px 1px 3px rgba(0, 0, 0, 0.14));
            filter: drop-shadow(3px 1px 3px rgba(0, 0, 0, 0.14));
    transition: opacity 0.5s ease, transform 0.2s ease;
    transform: scale(0);
}

.micro_img.pre_micro {
    animation: 1s ease-in 1s infinite alternate zoom_in_and_out;
}

.micro_img.disappear {
    transform: scale(0);
}

.stop_img {
    padding-top: 4%;
    padding-bottom: 4%;
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 130px;
    display: none;
    -webkit-filter: drop-shadow(3px 1px 3px rgba(0, 0, 0, 0.14));
            filter: drop-shadow(3px 1px 3px rgba(0, 0, 0, 0.14));
    transition: opacity 0.5s ease, transform 0.2s ease;
    transform: scale(0);
}

.stop_img.appear {
    transform: scale(1);
}

.micro_container {
    z-index: 1;
    position: relative;
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.micro_label {
    font-style: italic;
    font-size: 22px;
    height: 44px;
    margin-top: 10px;
    font-weight: 500;
    margin: 0;
}

.loading_micro {
    opacity: 0.5;
    width: 0px;
}

.finished_micro {
    transform: scale(0);
}

@keyframes zoom_in_and_out {
    from { transform: scale(1); }
    to { transform: scale(1.1); }
}

.wave1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    display: block;
    width: 300px;
    height: 300px;
    background: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
    border-radius: 100%;
    transition: transform 0.2s linear;
}

.wave2 {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    width: 200px;
    height: 200px;
    background: radial-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
    border-radius: 100%;
    transition: transform 0.2s linear;
}


.wave_container {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: scale(0) translate(-50%, -50%);
    transform-origin: top left !important;
    transition: transform 0.6s ease;
}

.wave_appear {
    z-index: -1;
    transform: scale(1) translate(-50%, -50%) !important;
}
.main_texts {
    margin-left: 13%;
    margin-right: 13%;
    height: 150px;
    display: block;
    position: relative;
    width: 74%;
}

.main_texts > span{
    width: 100%;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.main_texts:first-of-type {
    margin-top: 0;
}

.Micro {
    transition: transform 0.2s ease, opacity 0.2s ease;
    transform: scale(1);
    opacity: 1;
}

.MicroEnd {
    transform: scale(0);
    opacity: 0;
}
.brand_page {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    display: block;
    min-height: 100vh;
    width: 100vw;
}

.brand_container {
    min-height: 100vh;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
}

.brand_header{
    padding-top: 12px;
    line-height: 17vh;
    height: 17vh;
    transition: line-height 0.5s ease, height 0.5s ease;
}

.smaller_header{
    line-height: 8vh;
    height: 8vh;
}

.brand_header > img {
    vertical-align: middle;
}

@media (max-width: 850px) {
    .footer_text {
        bottom: 115px !important;
    } 
}

.footer_text {
    margin: 0;
    margin-top: 50px;
    font-size: 14px;
    opacity: 0.7;
}

.loading_text {
    line-height: 90vh;
    font-size: 20px;
    color: rgb(50, 50, 50);
}


.askWhyText {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    transition: opacity 0.2s ease;
    z-index: 10;
    width: min(80%, 500px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 20px;
}

.askWhyText > .title {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 20px;
    font-weight: 400;
}


.askWhyText > .choices > .choice {
    font-weight: 400;
    width: 100%;
    border-bottom:  1px solid rgba(255, 255, 255, 0.3);
    cursor: pointer;
}

.askWhyText > .choices > .choice:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.askWhyText > .choices > .choice > p {
    margin-left: 20px;
    margin-right: 20px;
    color: rgb(93, 167, 192);
}

.askWhyText > .choices > .choice:first-child {
    width: 100%;
    border-top:  1px solid rgba(255, 255, 255, 0.3);
}

.askWhyText > .choices > .choice:last-child {
    width: 100%;
    border-bottom: none;
}

.HomeHeader {
    display: block;
    top: 0;
    height: 60px;
    line-height: 60px;
    text-align: center;
    width: 100%;
    background-color: white;
    color: black;
    box-shadow: 0px 2px 3px lightgray;

}

.HomeHeader > p {
    font-weight: 400;
    margin: 0;
    font-size: 25px;
}

.BrandContainers {
    background-color: rgb(240, 240, 240) !important;
    min-height: 100vh;
    padding: 1%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.BrandCard {
    max-width: 250px;
    max-height: 140px;
    height: 28vw;
    line-height: min(28vw, 140px);
    display: block;
    margin: 1%;
    width: 48%;
    border-radius: 8px;
}

.BrandCard > img{
    vertical-align: middle;
    width: 40%;
}
body {
  background-color: black;
  text-align: center;
  color: white;
  font-family: 'Roboto' sans-serif;
  font-weight: 200;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

html, body {
  height: 100%;
}

.App {
  overflow: scroll;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
